<template>
    <div>
        <layout-wrapper>
            <div>
                Daftar Template
                <router-link :to="{ name: 'chatsetting' , query: { tabs: 'add' } }" v-if="showPage == 'list'">
                    <v-btn class="text-white pull-right btn btn-primary">
                        Buat Template
                    </v-btn>
                </router-link>
            </div>
            <div class="clearfix"></div>
            <br>
            <div class="wrapp-input-contact m-t-10">
                <div class="list-auto-template" v-if="showPage == 'list'">
                    <div class="row mb-2">
                        <div class="col-sm-12 col-md-4">
                            <v-text-field v-model="term" label="Judul" />
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <v-select class="" label="Kategori" :items="selectCategory" v-model="termCategory"></v-select>
                        </div>
                        <div class="col-sm-12 col-md-4 mt-3">
                            <v-btn class="btn mr-2" @click="searchTemplates">
                                Cari
                            </v-btn>
                            <v-btn class="btn btn-light" @click="resetFilter">
                                Reset
                            </v-btn>
                        </div>
                    </div>
                    <v-switch class="accent-4" v-model="enable" color="deep-purple accent-4"
                        :label="enable ? 'Tampilkan status aktif' : 'Tampilkan semua status'" />
                    <div class="mb-5">
                        <v-data-table :headers="headers" :items="desserts" :options.sync="options"
                            :server-items-length="totalElements" class="elevation-1" :loading="isLoadingTable"
                            sort-by="createdOn" :sort-desc="true" :footer-props="{ 'items-per-page-options': [5, 10, 15] }">
                            <template v-slot:item.title="{ item }">
                                <span class="Sender">
                                    {{ item.title }}
                                </span>
                            </template>
                            <template v-slot:item.shortDescription="{ item }">
                                <span class="emailPreview">
                                    {{ item.shortDescription }}
                                </span>
                            </template>
                            <template v-slot:item.action="{ item }">
                                <div class="row btn-action">
                                    <router-link :to="{ name: 'chatsetting' , params: {id: encodeURIComponent(item.code)}}">
                                        <b-button variant="outline-secondary" size="sm" class="btn ml-2"
                                            color="btn float-left" small>Ubah
                                        </b-button>
                                    </router-link>
                                    <DeleteDialog class="ml-2" :name="'Hapus Template'" :title="'Hapus Template'"
                                        :kind="'Template'" :shape="'icon'" @delete="deleteTemplate(item._id)" />
                                    <b-button title="Test Template" @click="openDialogTestTemplate(item)"
                                        variant="outline-secondary" size="sm" class="btn ml-2" color="btn float-left" small>
                                        Test
                                    </b-button>
                                </div>
                            </template>
                        </v-data-table>
                    </div>
                </div>
                <FormTemplate :showPage="showPage" :channels="channels" />
            </div>
        </layout-wrapper>

        <SuccessNotif />
        <TestTemplateDialog :dialogTestTemplate="showDialogTestTemplate" :template="selectedTemplate"
            @close-dialog="closeDialogTestTemplate" />
    </div>
</template>
<script>
    import axios from 'axios'
    import Scrollbar from 'smooth-scrollbar'
    import MainLayout from "../layouts/MainLayout"
    import SuccessNotif from "../components/SuccessNotif"
    import FormTemplate from '../components/FormTemplate.vue'
    import TestTemplateDialog from '../components/TestTemplateDialog.vue'
    import DeleteDialog from '../components/DeleteDialog.vue';
    import Toast from '../plugins/vuenotification'
    import {
        mapState
    } from 'vuex'
import LayoutWrapper from '../layouts/components/LayoutWrapper.vue'

    const moment = require('moment');
    const $ = require("jquery")
    const debounce = require('lodash.debounce')
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup')

    var userScrollBar = null

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            })
        },
        data: function () {
            return {
                formDialogValid: false,
                showDialogTestTemplate: false,
                selectedTemplate: Object(),
                isLoading: false,
                templateError: {},
                showPage: "list",
                isTyping: false,
                selectCategory: ['MARKETING', 'NOTIFICATION', 'NEWS'],
                headers: [{
                        text: 'Waktu',
                        value: 'time'
                    },
                    {
                        text: 'Kategori',
                        value: 'messageCategory'
                    },
                    {
                        text: 'Judul',
                        value: 'title'
                    },
                    {
                        text: 'Status',
                        value: 'status'
                    },
                    {
                        text: 'Deskripsi',
                        value: 'shortDescription'
                    },
                    {
                        text: 'Aksi',
                        value: 'action',
                        sortable: false
                    },
                ],
                desserts: [],
                options: {},
                totalElements: 0,
                term: String(),
                termCategory: String(),
                channels: Array(),
                enable: true,
                isLoadingTable: false,
                rules: {
                    required: [(v) => !!v || ""],
                },
            }
        },
        watch: {
            options: {
                handler() {
                    this.getTemplates();
                },
                deep: true,
            },
            'commChannelUIFlag.searchTerm': debounce(function () {
                this.isTyping = false;
            }, 1000),
            $route: async function (to, from) {
                if (this.$helpers.isBlank(to.params.id)) {
                    if (this.$route.query.tabs == 'add') {
                        this.showPage = 'form'
                    } else {
                        this.showPage = 'list'
                    }
                } else if (to.params.id != from.params.id) {
                    this.showPage = 'form'
                }
            },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            },
            isTyping: function (value) {
                if (!value) {
                    this.loadCommChannel();
                }
            }
        },
        methods: {
            closeDialogTestTemplate: function (val) {
                this.showDialogTestTemplate = val
            },
            openDialogTestTemplate: function (item) {
                this.showDialogTestTemplate = true
                this.selectedTemplate = item
            },
            resetFilter: function () {
                this.term = String()
                this.termCategory = String()
                this.enable = true

                if (this.options && this.options.page == 1) {
                    this.getTemplates()
                } else {
                    this.options.page = 1
                }
            },
            deleteTemplate: async function (templateId) {
                try {
                    await axios.delete(process.env.VUE_APP_API_URL + '/template/' + templateId)

                    await this.getTemplates()
                } catch (error) {
                    console.log('delete-broadcast', error);
                    Toast.showToast("error", error.response.data.message)
                }
            },
            searchTemplates: async function () {
                if (this.options.page != 1) {
                    this.options.page = 1;
                } else {
                    await this.getTemplates();
                }
            },
            getTemplates: async function () {
                this.isLoadingTable = true
                let params = Object();
                params.term = this.term
                params.enable = this.enable ? this.enable : String()
                params.category = this.termCategory

                if (this.options && this.options.page) {
                    params.page = this.options.page;
                }
                if (this.options && this.options.itemsPerPage) {
                    params.size = this.options.itemsPerPage;
                }
                if (this.options && this.options.sortBy.length > 0) {
                    params.sort = this.options.sortBy[0]
                }
                if (this.options && this.options.sortDesc.length > 0) {
                    params.sortDir = this.options.sortDesc[0] ? 'desc' : 'asc'
                }

                try {
                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/template', {
                        params: params,
                    })

                    const temp = data.content.map(template => {
                        template.status = template.enable ? 'Aktif' : 'Tidak Aktif'
                        template.time = moment(template.createdOn).locale('id').format('l')
                        return template
                    })

                    this.desserts = temp
                    this.totalElements = data.page.totalElements
                } catch (error) {
                    console.log(error);
                }
                this.isLoadingTable = false
            },
            loadInitialData: async function () {
                if (this.$route.params.id != null) {
                    this.showPage = 'form'
                    this.mode = 'edit'
                } else {
                    if (this.$route.query.tabs == 'add') {
                        this.showPage = 'form'
                    } else {
                        this.showPage = 'list'
                    }
                }
            },
            getChannels: async function() {
                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/commchannel', {
                        params: {
                            page: 1,
                            size: 100,
                            term: String()
                        },
                    })

                    this.channels = data.content.filter(e => e.channel_type == 'WACLOUDAPI')
                } catch (error) {
                    console.log("error-get-channel", error)
                }
            }
        },
        created: function () {
            this.$emit('update:layout', MainLayout);
        },
        mounted: async function () {
            Scrollbar.initAll();
            userScrollBar = Scrollbar.get($('#left-inner-scrollbar').get()[0]);
            console.log(userScrollBar);
            if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
                this.loadInitialData();
            }
            try {
                mainuisetup();
                commonpagesetup();
                this.getChannels()
            } catch (err) {
                console.log(err);
            }
        },
        components: {
            'layout-wrapper': LayoutWrapper,
            SuccessNotif,
            FormTemplate,
            TestTemplateDialog,
            DeleteDialog
        }
    }
    
</script>